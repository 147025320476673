import React, { useEffect, useState, useRef } from 'react'
import './index.styl'
import AnnouncementCard from './AnnouncementCard'
import HoursCalendar from './HoursCalendar'
import SessionAndPassesCard from './SessionAndPassesCard'
import UpcomingEventsCard from './UpcomingEventsCard'
import { useSelector } from 'react-redux'
import NewChatFrame from './NewChatFrame'

const Body = ({ announcements, sessions, passes, upcomingEvents, items, workingHours, id, userId, additionalChats = [], currentUser }) => {
  const [chats, setChats] = useState([])
  const [selectedChatId, setSelectedChatId] = useState(null)

  const previousChatId = useRef(null) // Ref to store previous chats[0]?.id
  const { token } = useSelector(state => state.session)
  const { isMembershipMember } = useSelector(state => state.memberships)

  // Effect for setting chats
  useEffect(() => {
    if (Array.isArray(additionalChats) && additionalChats.length > 0) {
      setChats(additionalChats)
    }
  }, [additionalChats])

  // Effect for updating selected chat ID when chats[0]?.id changes
  useEffect(() => {
    const currentChatId = chats[0]?.id

    // Ensure that chats array has at least one chat
    if (currentChatId && currentChatId !== previousChatId.current) {
      previousChatId.current = currentChatId // Update ref with new chat ID
      setSelectedChatId(currentChatId) // Dispatch the action to update the selected chat ID
    }
  }, [chats]) // Trigger effect on change in chats

  const updateChats = (chatID, lastMessage) => {
    setChats(prevChats =>
      prevChats.map(chat =>
        chat.id === chatID ? { ...chat, lastMessage } : chat,
      ),
    )
  }
  const isOwnerPage = currentUser === userId

  return (
    <div className='memberBodyWrapper'>
      {announcements?.length > 0 && <AnnouncementCard announcements={announcements} />}
      {workingHours && <HoursCalendar schedule={workingHours} />}
      <SessionAndPassesCard id={id} userId={userId} sessions={sessions} passes={passes} items={items} />
      {upcomingEvents?.length > 0 && <UpcomingEventsCard upcomingEvents={upcomingEvents} />}
      {selectedChatId && (isMembershipMember || isOwnerPage) && (
        <NewChatFrame setSelectedChatId={setSelectedChatId} currentUser={currentUser} userId={userId} chats={chats} token={token} chatID={selectedChatId} updateChats={updateChats} />
      )}
    </div>
  )
}

export default Body
