import React, { useEffect, useState } from 'react'
import { Divider, Form, Switch } from 'antd'
import UpdatedTicketCategory from '../../UpdatedTicketCategory'
import TooltipInfo from '../../../../../CreateEventV2/TooltipInfo'

const { Item, List } = Form
const RaffleCategory = ({ currentEventDetails }) => {
  const [raffleSwitch, setRaffleSwitch] = useState(false)
  const donationTicketHandler = () => {
    setRaffleSwitch(!raffleSwitch)
  }
  useEffect(() => {
    if (!!currentEventDetails?.raffle?.length) {
      setRaffleSwitch(true)
    }
  }, [currentEventDetails])

  const tooltipTitle = `Charity Raffles are limited to $30,000 in total ticket sales and $100 max price per ticket

  Ticket Lyfe charges for Charity Raffle Tickets are $1 for Raffles priced up to $50 and $2 for higher priced Raffle Tickets

  Do not pick Charity Raffles if you are doing a regular event with Random Draw Prizes--Because you can do a Random Draw on ANY Ticket Lyfe Event, the Charity Raffle limits above will not apply, and Ticket Lyfe treat your event as an ordinary Ticketed Event`

  const additionalTooltip = (
    <TooltipInfo
      className='tooltipTitle'
      text='Additional info'
      info={tooltipTitle}
    />
  )

  return (
    <div className='typeCategoryWrapper'>
      <div className='wrapperTitleRadioText'>
        <div className='titleWrapper'>
          <div className='title'>Charity Raffle</div>
          <div className='tooltipWrapper'>{additionalTooltip}</div>
        </div>
        <div className='radioItem'>
          <Item style={{ marginBottom: 0 }} name='raffleSwitch' valuePropName='checked' className='item'>
            <Switch onChange={donationTicketHandler} defaultChecked={raffleSwitch} />
          </Item>
          <div className='text'>Enable Charity Raffle</div>
        </div>
      </div>
      {raffleSwitch &&
        <List name='raffle'>
          {(fields, { add, remove }) => (
            <div className='updatedCategoriesWrapper'>
              {fields.map((field, i) =>
                <div key={field.fieldKey}>
                  <UpdatedTicketCategory
                    categoryType='Raffle'
                    namePrefix={i}
                    fieldKey={field.fieldKey}
                    fieldsLength={fields.length}
                    switchToggleOff={setRaffleSwitch}
                    onRemove={remove}
                  />
                </div>,
              )}
              {fields.length === 0 && (
                <div key='defaultForm'>
                  {/* Render default form when no categories exist */}
                  <UpdatedTicketCategory
                    categoryType='Raffle'
                    namePrefix={0}
                    fieldsLength={fields.length + 1}
                    switchToggleOff={setRaffleSwitch}
                    onRemove={remove}
                  />
                </div>
              )}
              <div
                id='addCategory' className='addCategoryBtn' onClick={() => {
                  add()
                }}
              >Add Another Charity Raffle Category
              </div>
            </div>)}
        </List>}
      <Divider />
    </div>
  )
}

export default RaffleCategory
